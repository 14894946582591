<template>
  <div class="relative">
    <img
        class="absolute top-0 -left-6"
        src="@src/assets/img/common/new_tag.svg"
        alt="new-tag"
    />
    <button
      class="
        mr-[0.5rem]
        text-[#757A8A]
        align-items-center
        !bg-[#F9F9F9]
        inline-flex
        gap-[0.5rem]
        text-[0.875rem]
        leading-4
        !border
        border-solid
        font-weight-400
        py-[0.67rem]
        px-[1rem]
        !border-[#D2D5DF]
        rounded-[0.3rem]
      "
      :class="{ '!py-[0.295rem] ': getAttachedLabels.length }"
      @click="showLabelDropdown = !showLabelDropdown"
    >
      <img
        v-tooltip="'Labels'"
        src="@src/assets/img/chat_bot/labels.svg"
        alt="label icon"
        class="h-[1rem] w-[1rem]"
      />
      <template v-if="getAttachedLabels.length">
        <!--Show the Attached Labels -->
        <template v-if="getAttachedLabels.length > 2">
          <span
            v-for="(label, index) in getAttachedLabels.slice(0, 2)"
            :key="index"
            class="px-3 py-1 rounded-md text-sm flex items-center gap-1"
            :style="getLabelColor(label.color)"
            v-tooltip="{
                    content: label.name.length > 22 ? label?.name : '',
                  }"
          >
            {{
              label.name.length > 24
                ? label.name.substring(0, 24) + '...'
                : label.name
            }}
          </span>
          <span
            class="
              rounded-full
              bg-[#FFC555]
              text-center text-[#333333]
              w-6
              h-6
              flex
              items-center
              justify-center
            "
          >
            {{ getAttachedLabels.length - 2 }}+
          </span>
        </template>
        <template v-else>
          <span
            v-for="(label, index) in getAttachedLabels"
            :key="index"
            class="px-3 py-1 rounded-md text-sm flex items-center gap-1"
            :style="getLabelColor(label.color)"
            v-tooltip="{
                    content: label.name.length > 22 ? label?.name : '',
                  }"
          >
            {{
              label.name.length > 24
                ? label.name.substring(0, 24) + '...'
                : label.name
            }}
          </span>
        </template>
      </template>
      <span v-else>Label</span>
    </button>

    <div v-if="showLabelDropdown" class="absolute top-full right-2 mt-1 z-10" v-click-away="handleSubmit">
      <div
        class="
          w-80
          bg-white
          rounded-xl
          shadow-md
          border border-gray-200
          overflow-hidden
          transition
          duration-300
          ease-in-out
        "
      >
        <!-- Header -->
        <div
          class="
            flex
            justify-between
            items-center
            px-4 py-3
            border border-b border-gray-100
          "
        >
          <!-- Add Label-->
          <div v-if="!isSearchActive" class="flex items-center gap-2">
            <span class="text-[#3A4557] font-medium text-base">Labels</span>
            <img
              v-tooltip="'Add a new Label'"
              src="@src/assets/img/common/add-circle.svg"
              class="h-5 w-5 cursor-pointer"
              alt="add label"
              @click.stop="showAddLabel = !showAddLabel"
            />
          </div>

          <!-- Search -->
          <div
            class="flex items-center bg-gray-100/70 rounded-md px-3 py-1"
            :class="{ 'w-full': isSearchActive }"
          >
            <img
              src="@src/assets/img/chat_bot/search-icon.svg"
              class="h-4 w-4"
              alt="search"
            />
            <input
              v-model="searchQuery"
              type="text"
              class="
                bg-transparent
                w-20
                ml-1
                text-sm
                border-0
                p-1
                rounded
              "
              :class="{ '!w-full': isSearchActive }"
              placeholder="Search"
              @focus="isSearchActive = true"
            />
            <img
              v-show="isSearchActive"
              src="@src/assets/img/common/circle-xmark.svg"
              alt="xmark"
              class="h-4 w-4 cursor-pointer"
              @click.stop="resetSearch"
            />
          </div>
        </div>

        <!-- Add Label-->
        <div
          v-if="showAddLabel"
          class="px-4 py-2 border border-b border-gray-100"
        >
          <div class="mb-3 relative">
            <input
              v-model="labelName"
              type="text"
              placeholder="Type label name"
              class="
                w-full
                pl-10
                pr-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
              "
            />
            <img
              src="@src/assets/img/chat_bot/labels.svg"
              alt="label icon"
              class="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2"
            />
          </div>

          <div class="flex items-center justify-between mb-2">
            <div class="flex gap-2 relative mt-1 ml-2">
              <p class="text-sm font-medium text-gray-900 mb-2 ">Color</p>
              <img
                src="@src/assets/img/common/color-picker.svg"
                class="
                  h-5
                  w-5
                  cursor-pointer
                  rounded-md
                  p-0.5
                  border border-gray-400
                "
                :style="{ backgroundColor: LABELS_COLOR_MAP[selectedColor] }"
                alt="color picker"
                @click="showColorPicker = !showColorPicker"
                v-click-away="hideColorPicker"
              />
              <div
                v-if="showColorPicker"
                class="
                  absolute
                  left-0
                  top-full
                  bg-white
                  rounded-xl
                  w-72
                  p-1
                  grid grid-cols-10
                  shadow-sm
                  gap-1
                  border border-gray-200
                "
                @click.stop
              >
                <div
                  v-for="color in Object.values(LABELS_COLOR_MAP)"
                  :key="color"
                  :class="`w-6 h-6 rounded-md border border-gray-200 relative cursor-pointer`"
                  :style="`background-color: ${color}`"
                  @click="selectColor(color)"
                >
                  <img
                    v-if="color === LABELS_COLOR_MAP[selectedColor]"
                    src="@src/assets/img/chat_bot/tick.svg"
                    alt="selected"
                    class="absolute inset-0 m-auto w-3 h-3"
                  />
                </div>
              </div>
            </div>

            <div class="flex justify-end gap-2 mr-2">
              <CstButton size="small" variant="text" @click.stop="resetStates">
                Cancel
              </CstButton>
              <CstButton
                size="small"
                :disabled="!labelName || !selectedColor"
                variant="primary"
                @click.stop="handleClick"
              >
                {{ isEditing ? 'Update' : 'Create' }}
              </CstButton>
            </div>
          </div>
        </div>

        <!-- Labels -->
        <div class="!max-h-96 overflow-y-auto my-2">
          <div v-if="!LabelsExist" class="flex items-center justify-center my-4">
            <p class="text-center text-sm text-[#757A8A] font-normal">No labels yet. Click</p>
            <img
                src="@src/assets/img/common/add-circle.svg"
                class="h-5 w-5 cursor-pointer mx-1"
                alt="add label"
                @click.stop="showAddLabel = true"
            />
            <p class="text-center text-sm text-[#757A8A] font-normal">to create one.</p>
          </div>
          <div
            v-for="(label, index) in searchLabels()"
            :key="index"
            class="flex flex-col px-4 py-2 group hover:bg-blue-50/30"
            :class="{ 'bg-blue-50/30': label.showDeleteConfirmation }"
          >
            <div class="flex justify-between items-center my-1">
              <label class="flex items-center cursor-pointer !mb-0">
                <input
                  v-model="selectedLabels"
                  :value="label._id"
                  type="checkbox"
                  class="hidden"
                />
                <span class="checkbox-icon">
                  <span
                    v-if="!selectedLabels.includes(label._id)"
                    class="group"
                  >
                    <img
                      src="@src/assets/img/common/unchecked.svg"
                      alt="Unchecked"
                      class="w-5 h-5"
                    />
                  </span>
                  <img
                    v-else
                    src="@src/assets/img/common/checked.svg"
                    alt="Checked"
                    class="w-5 h-5"
                  />
                </span>
                <span
                  class="ml-2 px-3 py-1 rounded-md text-sm"
                  :style="getLabelColor(label.color)"
                  v-tooltip="{
                    content: label.name.length > 22 ? label?.name : '',
                  }"
                >
                  {{
                    label.name.length > 22
                      ? label.name.substring(0, 22) + '...'
                      : label.name
                  }}
                </span>
              </label>
              <div
                v-if="!label.showDeleteConfirmation"
                class="hidden group-hover:flex"
              >
                <div
                  class="
                    cursor-pointer
                    p-1
                    mx-0.5
                    font-semibold
                    text-sm text-[#2f8ae0]
                  "
                  @click.stop="editLabel(index)"
                >
                  <i v-tooltip="'Edit'" class="edit_icon icon-edit-cs"></i>
                </div>
                <div
                  class="
                    cursor-pointer
                    p-1
                    mx-0.5
                    font-semibold
                    text-sm text-[#db5353]
                  "
                  style="color: #db5353"
                  @click.stop="toggleDeleteConfirmation(index)"
                >
                  <i
                    v-tooltip="'Remove'"
                    class="remove_icon icon-delete-cs"
                  ></i>
                </div>
              </div>
            </div>

            <div v-if="label.showDeleteConfirmation" class="mt-2">
              <p class="text-sm text-left text-gray-900 ml-8">
                Deleting this label will also remove it from all other posts
                where it's been used.
              </p>
              <div class="float-right gap-0.5">
                <CstButton
                  @click.stop="toggleDeleteConfirmation(index)"
                  variant="text"
                  size="small"
                  class="!text-gray-700 "
                >
                  Cancel
                </CstButton>
                <CstButton
                  class="text-red-600 hover:text-red-800"
                  @click.stop="deleteLabel(label)"
                  size="small"
                  variant="text"
                >
                  Delete
                </CstButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Core Imports
import {computed, defineEmits, defineProps, inject, ref} from 'vue'

// Other Imports
import CstButton from '@ui/Button/CstButton.vue'
import { useStore } from '@state/base'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'
// Helpers
const emit = defineEmits()
const store = useStore()
const root = inject('root')
const { $bvModal } = root
const { LABELS_COLOR_MAP, attachLabel } = useLabelAndCampaign()

// Props
const props = defineProps({
  labels: {
    type: Array,
    default: () => [],
  },
  attachedLabels: {
    type: Array,
    default: () => [],
  },
  planId: {
    type: String,
    default: '',
  },
})

// Data
const showLabelDropdown = ref(false)
const showAddLabel = ref(false)
const selectedColor = ref('color_1')
const labelName = ref('')
const showColorPicker = ref(false)
const searchQuery = ref('')
const labels = ref(store.getters?.getLabels || [])
const isEditing = ref(false)
const editingIndex = ref(null)
const selectedLabels = ref(props.attachedLabels || [])
const isSearchActive = ref(false)

// Computed

// Get the labels attached to current plan
const getAttachedLabels = computed(() => {
  return labels.value?.filter((label) =>
    selectedLabels.value?.includes(label?._id)
  )
})

// Check if Labels exist
const LabelsExist = computed(() => {
  return labels.value?.length
})

// check if props labels and attached label's ids are same
const isSame = computed(() => {
  // Check if both arrays are defined and have the same length
  if (!props.labels || !selectedLabels.value || props.labels.length !== selectedLabels.value.length) {
    return false;
  }

  // Create a Set from one of the arrays for efficient lookup
  const labelSet = new Set(props.labels);

  // Check if all elements in selectedLabels are in the Set
  return selectedLabels.value?.every(id => labelSet.has(id));
});

// Methods

// Handle Click
const handleClick = () => {
  if (isEditing.value) {
    updateLabel()
  } else {
    createLabel()
  }
}

const selectColor = (color) => {
  selectedColor.value = getObjectKey(color)
  showColorPicker.value = false
}

const resetStates = () => {
  showAddLabel.value = false
  labelName.value = ''
  showColorPicker.value = false
  selectedColor.value = 'color_1'
  isEditing.value = false
  editingIndex.value = null
}

// Attach Label
const attachLabelToPlan = async () => {
  const data = {
    id: props.planId,
    label_ids: selectedLabels.value,
  }

  // Attach the label to the plan
  await attachLabel(data)

  // Update the attached labels by filtering the selected labels
  emit('update:attachedLabels', selectedLabels.value)

  // Close the dropdown
  showLabelDropdown.value = false
}

// Save Labels upon closing the dropdown
const handleSubmit = () => {
  if (!isSame.value) {
    attachLabelToPlan()
  }else{
    handleClose()
  }
  showLabelDropdown.value = false
}

// Create Label
const createLabel = async () => {
  if (labelName.value && selectedColor.value) {
    store.commit('SET_NEW_LABEL', {
      name: labelName.value,
      color: selectedColor.value,
    })
    await store.dispatch('saveLabel')
    resetStates()

    // Wait for the label to be saved
    setTimeout(() => {
      // select the newly created label
      selectedLabels.value = [
        ...selectedLabels.value,
        store.getters.getLabels[0]?._id,
      ]
    }, 1000)
  }
}

const updateLabel = () => {
  // Update the label in the store
  store.commit('SET_NEW_LABEL', {
    _id: labels.value[editingIndex.value]?._id,
    name: labelName.value,
    color: selectedColor.value,
  })
  store.dispatch('saveLabel')
  resetStates()
}

const editLabel = (index) => {
  isEditing.value = true
  editingIndex.value = index
  labelName.value = labels.value[index].name
  selectedColor.value = labels.value[index].color
  showAddLabel.value = true
}

const deleteLabel = async (label) => {
  await store.dispatch('deleteLabel', label)
  // Update the selected labels
  selectedLabels.value = selectedLabels.value?.filter(
    (selectedLabel) => selectedLabel !== label?._id
  )
  // Close the delete confirmation view
  label.showDeleteConfirmation = false
}

const toggleDeleteConfirmation = (index) => {
  labels.value[index].showDeleteConfirmation =
    !labels.value[index].showDeleteConfirmation
}

const searchLabels = () => {
  return labels.value?.filter((label) =>
    label.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  )
}


const getLabelColor = (color) => {
  const backgroundColor = hexToRGBA(LABELS_COLOR_MAP[color], 0.1)
  const textColor =
    LABELS_COLOR_MAP[color] === '#000' ? '#fff' : LABELS_COLOR_MAP[color]
  return {
    backgroundColor, // Lighter background
    color: textColor, // Adjust text color if needed
  }
}

// Helper function to convert hex to RGBA
const hexToRGBA = (hex, opacity) => {
  if (!hex || !/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
    return `rgba(0, 0, 0, ${opacity})` // Default to black with given opacity
  }
  const rgb = hex
    .replace('#', '')
    .match(/.{1,2}/g)
    .map((x) => parseInt(x, 16))
  return `rgba(${rgb.join(',')}, ${opacity})`
}

// Get key of object
const getObjectKey = (value) => {
  return Object.keys(LABELS_COLOR_MAP)?.find(
    (key) => LABELS_COLOR_MAP[key] === value
  )
}

// Handle Close
const handleClose = () => {
  showLabelDropdown.value = false
  selectedLabels.value = props.attachedLabels || []
  resetStates()
}

// Reset Search
const resetSearch = () => {
  searchQuery.value = ''
  isSearchActive.value = false
}

// Hide Color Picker
const hideColorPicker = () => {
  showColorPicker.value = false
}
</script>

<style scoped></style>
