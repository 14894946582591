<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {swalAttributes} from "@common/constants/common-attributes";
import {EventBus} from "@common/lib/event-bus";
import {useComposerHelper} from "@src/modules/composer_v2/composables/useComposerHelper";
import useDateFormat from "@common/composables/useDateFormat";
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'

export default {
  components: {
    SkeletonBox
  },
  setup(){
    const { getSocialImageRounded } = useComposerHelper()
    const { momentWrapper } = useDateFormat()
    return {
      getSocialImageRounded,
      momentWrapper
    }
  },
  data () {
    return {
      loader : false
    }
  },
  computed: {
    ...mapGetters([
      'getScheduledReports',
      'getScheduledReportItem',
      'getFacebookPagesList',
      'getFacebookAccounts',
      'getInstagramAccounts',
      'getTwitterAccounts',
      'getLinkedinCompanyPages',
      'getTiktokAccounts',
      'getYoutubeAccounts',
    ]),
    reportsList () {
      if (
          this.getScheduledReports?.query?.length > 0
      ) {
        return this.getScheduledReports.list?.filter((item) => {
          if (
              item.name.toLowerCase().includes(this.getScheduledReports.query)
          ) {
            return item
          }
        })
      } else {
        return this.getScheduledReports.list
      }
    }
  },
  watch: {
    'getActiveWorkspace._id': async function (newVal, oldVal)
    {
      if (newVal !== oldVal) {
        await this.fetchScheduleReportsService();
      }
    }

  },
  async mounted ()
  {
    this.loader = true
    try {
      const res = await this.fetchScheduleReportsService();
      if (res) {
        this.loader = false
      }
    } catch (error) {
      console.error(error)
      this.loader = false
    }
  },
  methods: {
    ...mapActions([
      'fetchScheduleReportsService',
      'removeScheduledReportService'
    ]),
    ...mapMutations([
      'SET_SCHEDULED_REPORT_ITEM',
      'SET_SCHEDULED_REPORT_ITEM_ACCOUNTS'
    ]),
    editScheduledReport (item) {
      this.SET_SCHEDULED_REPORT_ITEM_ACCOUNTS(this.getAccounts(item))
      this.SET_SCHEDULED_REPORT_ITEM(structuredClone(item))
      $('#scheduleReport').modal('show')
      EventBus.$emit('edit-schedule-report', {reportData: item})
    },
    getIconClass (type) {
      switch (type) {
        case 'group':
        case 'individual':
          return 'fa fa-pie-chart'
        case 'facebook':
          return 'fab fa-facebook-f'
        case 'instagram':
          return 'fab fa-instagram'
        case 'linkedin':
          return 'fab fa-linkedin'
        case 'tiktok':
          return 'fab fa-tiktok'
        case 'youtube':
          return 'fab fa-youtube'
        default:
          return `fa-${type}`
      }
    },
    getAccounts (item) {
      switch (item.type) {
        case 'group':
        case 'individual':
          return item.accounts
        case 'facebook':
          return this.getFacebookPagesList.filter(
              (i) => i.facebook_id === item.accounts
          )
        case 'instagram':
          return this.getInstagramAccounts.items.filter(
              (i) => i.instagram_id === item.accounts
          )
        case 'twitter':
          return this.getTwitterAccounts.items.filter(
              (i) => i.twitter_id === item.accounts
          )
        case 'linkedin':
          return this.getLinkedinCompanyPages.filter(
              (i) => i.linkedin_id === item.accounts
          )
        case 'tiktok':
          return this.getTiktokAccounts.items.filter(
              (i) => i.platform_identifier === item.accounts
          )
        case 'youtube':
          return this.getYoutubeAccounts.items.filter(
              (i) => i.platform_identifier === item.accounts
          )
      }
    },
    async handleDeleteReport(reportName, reportId, reportIndex) {
      const res = await this.$bvModal.msgBoxConfirm(
          `Are you sure you want to delete report "${reportName}" ?`,
          {
            title: 'Delete Report',
            ...swalAttributes()
          }
      )
      if(!res) return
      await this.removeScheduledReportService({
        reportId,
        index: reportIndex,
      })
    }
  },
}
</script>

<template>
  <div class="analytic_common_component custom_report_main">
    <div class="component_inner">
      <div class="section_top_bar">
        <div
          v-if="reportsList && reportsList.length > 0"
          class="section_top_bar_inner max_container_1800 d-flex height align-items-center"
        >
          <div class="left basic_form">
            <div class="input_field mt-0">
              <input
                v-model="getScheduledReports.query"
                type="text"
                placeholder="Search report..."
              />
            </div>
          </div>
          <!--<div class="right ml-auto">-->
          <!--</div>-->
        </div>
      </div>

      <div class="report_list clear">
        <h2 class="heading">My Reports</h2>
        <div class="clearfix"></div>
        <!--<div class="report_box create_report"
            v-if="!getScheduledReports.query || getScheduledReports.query.length === 0">
           <div class="box_inside">
               <span class="plus_icon">&times;</span>
               <h3>Create New Report</h3>
           </div>
       </div>-->
        <!-- Loader-->
        <template v-if="loader">
          <div class="row">
            <div class="col-md-3" v-for="index in 4" :key="index">
              <SkeletonBox
                  width="100%"
                  height="250px"
                  radius="12px"
              />
            </div>
          </div>
        </template>
        <template v-else-if="reportsList && reportsList.length > 0">
          <div class="row">
            <div v-for="(item, key) in reportsList" :key="`item_key_${key}`" class="report_box">
              <div class="box_inside">
                <i
                  class="icon d-flex align-items-center justify-content-center"
                  :class="getIconClass(item.platform_type ? item.platform_type : item.type)"
                ></i>
                <h3>{{ item.name }}</h3>
                <template v-if="item.accounts">
                <div class="d-flex bg_main pb-2">
                    <!-- for the social channels tooltip -->
                    <template v-for="(channel, index) in Array.isArray(item.accounts) ? item.accounts : [item.accounts]">
                      <div v-if="index < 3" :key="index" class="flex-row">
                        <div class="ml-[-10px] relative">
                          <img
                              :src="channel && channel.image ? channel.image : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
                              class="bg"
                              alt=""
                              @error="
                              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                        "/>
                          <img
                              :src="getSocialImageRounded(channel.account_type)"
                              alt=""
                              class="absolute -right-0.5 -bottom-0.5 !w-[1.125rem] !h-[1.125rem]"
                          />
                        </div>
                      </div>
                    </template>
                    <div
                      v-if="
                        item.accounts.length > 3
                      "
                      class="flex-row"
                    >
                      <div class="bg_count" style="margin-left: -10px">
                        <p>+ {{ item.accounts.length - 3 }}</p>
                      </div>
                    </div>
                  </div>
                </template>
                <p class="report-created-at"
                  >Created:
                  {{ momentWrapper(item.created_at).formatDate() }}</p
                >
                <p class="report-interval">
                  <span>
                    {{ item.interval }}
                  </span>
                </p>

                <div class="btn_block">
                  <i v-tooltip="'Edit Report'" class="fa fa-edit" @click="editScheduledReport(item)"></i>
                  <i
                      v-tooltip="'Delete Report'"
                    class="icon-delete-cs"
                    @click="
                      handleDeleteReport(item.name, item._id, key)
                    "
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="reportsList && reportsList.length === 0">
          <div class="not_found_text">
            <div class="no_data_found_content">
              <div class="no_data_found_content_inner">
                <div class="img">
                  <img
                    src="../../../../assets/img/no_data_images/no_data_found.svg"
                    alt=""
                  />
                </div>
                <p>No reports Scheduled Yet</p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.report-created-at {
  padding-bottom: 10px;
}

.report-interval p {
  margin-top: 30px !important;
}

.report-interval span {
  padding: 2px 14px;
  /*left: 15px;*/
  /*top: 15px;*/
  font-size: 13px;
  text-transform: capitalize;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: rgba(65, 101, 237, 0.2);
  border: solid 1px #4165ed;
  color: #4165ed;
  letter-spacing: 0.7px;
  -webkit-transition: all 0.2s ease-in !important;
  -moz-transition: all 0.2s ease-in !important;
  -o-transition: all 0.2s ease-in !important;
  transition: all 0.2s ease-in !important;
}
</style>
