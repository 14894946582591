<template>
  <div class="analytics-filter-bar !top-0">
    <!-- Loader (When Workspace Loaded) -->
    <div v-if="getFetchSocialStatus" class="analytics-filter-bar__left">
      <b-dropdown
        ref="dropdown"
        variant="studio-theme"
        size="lg"
        disabled
        class="
          studio-theme-dropdown
          studio-theme-dropdown--checkbox
          studio-theme-dropdown--contain-image
          analytic-accounts-dropdown
        "
        no-caret
      >
        <template v-slot:button-content>
          <span class="connected-accounts-images">
            <i class="fad fa-user-circle mr-0" style="font-size: 1.8rem"></i>
          </span>
          <span
            class="capitalize_text"
            style="
              text-align: left;
              display: inline-flex;
              flex-direction: column;
              margin: 0 1rem;
              font-weight: 500;
              justify-content: flex-start;
            "
          >
            Accounts
            <small>Are Loading...</small>
          </span>
          <i class="icon-dropdown-cs"></i>
        </template>
      </b-dropdown>
      <clip-loader
        v-if="updating"
        class="spinner ml-3"
        :color="'#9da6ac'"
        :size="'16px'"
      ></clip-loader>
    </div>
    <div v-else class="analytics-filter-bar__left">
      <CstDropdown
        v-if="type === 'overview'"
        class="w-full min-w-[25rem]"
        size="small"
        button-classes="flex !rounded-xl w-full"
        container-classes="max-h-[30rem]"
        @on-close="searchQuery = ''"
      >
        <template v-slot:selected>
          <span class="flex flex-row w-full items-center">
            <span class="connected-accounts-images">
              <template v-if="selected_accounts.length === 0">
                <i class="fad fa-user-circle text-3xl mr-0"></i>
              </template>
              <template
                v-for="(account, index) in localSelectedAccounts"
                :key="index + '_overview_select'"
              >
                <template v-if="index < 5">
                  <img
                    class="selected"
                    :src="channelImage(account, account.account_type)"
                    alt=""
                    @error="
                      $event.target.src = account?.name
                        ? fallbackImagePreview(account.name[0])
                        : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                    "
                  />
                </template>
              </template>
              <!-- Last -->
              <template v-if="getAllAccountsLength() > 5">
                <span class="selected">+{{ getAllAccountsLength() - 5 }}</span>
              </template>
            </span>
            <span
              class="
                capitalize_text
                text-left
                inline-flex
                flex-col
                mx-0.5
                font-bold
                justify-start
              "
            >
              {{ getAllAccountsLength() }} Account{{
                getAllAccountsLength() > 1 ? 's' : ''
              }}
              <small>Connected</small>
            </span>
          </span>
        </template>
        <template v-slot>
          <div class="py-2 px-3 sticky top-0 bg-white z-50 shadow-sm">
            <label class="w-full flex items-center mt-1">
              <input
                v-model="searchQuery"
                class="rounded-xl border py-2 pl-1 w-full text-grey-darkest"
                placeholder="Search Account By Name"
                type="text"
              />
              <i
                v-if="searchQuery.length"
                class="
                  fas
                  fa-times-circle
                  absolute
                  cursor-pointer
                  opacity-80
                  right-4
                  hover:text-red-400
                "
                @click="searchQuery = ''"
              ></i>
            </label>
            <SimpleCheckbox
              v-if="!searchQuery && selectAllAccounts().length > 1"
              v-model="all_selected"
              @change="selectUnselectAll"
            >
              <template v-slot:label>
                <span class="font-medium">Select All</span></template
              >
            </SimpleCheckbox>
          </div>
          <div>
            <template v-if="searchQuery">
              <template v-if="searchedAccounts?.length > 0">
                <div v-for="(account, i) in searchedAccounts" :key="i">
                  <CstDropdownItem :close-on-click="false">
                    <SimpleCheckbox
                      v-model="selected_accounts"
                      :name="account"
                      :disabled="!hasInstaPermission(account)"
                      class="group"
                    >
                      <template v-slot:label>
                        <span class="flex items-center">
                          <span class="relative">
                            <img
                              :src="channelImage(account, account.account_type)"
                              alt=""
                              class="rounded-full object-cover border"
                              data-fallback="0"
                              height="32"
                              width="32"
                              @error="
                                (event) => {
                                  event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                                }
                              "
                            />

                            <img
                              :src="
                                require('@assets/img/integration/' +
                                  getSocialImage(account.account_type))
                              "
                              alt=""
                              class="absolute -right-0.5 -bottom-0.5 w-5 h-5"
                              :class="{
                                'bg-white rounded-full':
                                  account?.account_type === 'twitter',
                              }"
                            />
                          </span>
                          <span class="flex flex-col ml-3">
                            <span class="flex flex-row">
                              <span
                                v-tooltip="{
                                  content: hasInstaPermission(account)
                                    ? account.name
                                    : isIGPersonalAccount(account)
                                    ? 'Analytics are not available for Instagram personal accounts.'
                                    : 'Insufficient permissions, please reconnect profile to enable Analytics.',
                                }"
                                class="
                                  text-sm text-ellipsis
                                  font-normal
                                  text-black-100
                                  select-none
                                "
                              >
                                {{ account.name }}
                              </span>
                            </span>
                            <span
                              class="block text-xs select-none text-gray-800"
                            >
                              {{
                                getProfileType(account.account_type, account)
                              }}
                            </span>
                          </span>
                        </span>
                      </template>
                    </SimpleCheckbox>
                  </CstDropdownItem>
                </div>
              </template>
              <template v-else>
                <CstDropdownItem
                  class="active:bg-transparent hover:bg-transparent"
                  :close-on-click="false"
                  :disabled="true"
                >
                  <span>No Account Found</span>
                </CstDropdownItem>
              </template>
            </template>
            <template v-else>
              <div v-for="(account, i) in selectAllAccounts()" :key="i">
                <CstDropdownItem :close-on-click="false">
                  <SimpleCheckbox
                    v-model="selected_accounts"
                    :name="account"
                    :disabled="!hasInstaPermission(account)"
                    class="group"
                  >
                    <template v-slot:label>
                      <span class="flex items-center">
                        <span class="relative">
                          <img
                            :src="channelImage(account, account.account_type)"
                            alt=""
                            class="rounded-full object-cover border"
                            data-fallback="0"
                            height="32"
                            width="32"
                            @error="
                              (event) => {
                                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                              }
                            "
                          />

                          <img
                            :src="
                              require('@assets/img/integration/' +
                                getSocialImage(account.account_type))
                            "
                            alt=""
                            class="absolute -right-0.5 -bottom-0.5 w-5 h-5"
                            :class="{
                              'bg-white rounded-full':
                                account?.account_type === 'twitter',
                            }"
                          />
                        </span>
                        <span class="flex flex-col ml-3">
                          <span class="flex flex-row">
                            <span
                              v-tooltip="{
                                content: hasInstaPermission(account)
                                  ? account.name
                                  : isIGPersonalAccount(account)
                                  ? 'Analytics are not available for Instagram personal accounts.'
                                  : 'Insufficient permissions, please reconnect profile to enable Analytics.',
                              }"
                              class="
                                text-sm text-ellipsis
                                font-normal
                                text-black-100
                                select-none
                              "
                            >
                              {{
                                account.name
                                  ? account.name
                                  : account.platform_name
                              }}
                            </span>
                          </span>
                          <span class="block text-xs select-none text-gray-800">
                            {{ getProfileType(account.account_type, account) }}
                          </span>
                        </span>
                      </span>
                    </template>
                  </SimpleCheckbox>
                </CstDropdownItem>
              </div>
            </template>
          </div>

          <div class="p-1 sticky bottom-0 border-top bg-white z-50">
            <CstDropdownItem
              class="
                active:bg-transparent
                hover:bg-transparent
                !p-0.5
                text-right
              "
              size="small"
              :close-on-click="true"
            >
              <CstButton
                size="small"
                text="Apply"
                @click="handleApplyAccountSelection"
              />
            </CstDropdownItem>
          </div>
        </template>
      </CstDropdown>
      <template v-else>
        <template v-if="isPinterest">
          <div class="mr-3">
            <b-dropdown
              ref="dropdown"
              variant="studio-theme"
              toggle-class="analytic-accounts-dropdown__btn"
              size="md"
              :disabled="updating || pinterestProfiles.length === 0"
              class="
                studio-theme-dropdown
                studio-theme-dropdown--checkbox
                studio-theme-dropdown--contain-image
                analytic-accounts-dropdown
              "
              :class="{
                '!min-w-[17rem]': isPinterest,
              }"
              no-caret
            >
              <template v-slot:button-content>
                <template v-if="pinterestProfiles.length === 0">
                  <i class="fad fa-user-circle !text-2xl !leading-9 mr-2"></i>
                  <span
                    class="
                      capitalize_text
                      text-left
                      inline-flex
                      flex-col
                      mx-0.5
                      font-bold
                      justify-start
                    "
                  >
                    <span class="font-normal">No Account Connected</span>
                  </span>
                </template>
                <template v-else>
                  <span
                    class="flex flex-row w-full justify-center items-center"
                  >
                    <span class="connected-accounts-images">
                      <div>
                        <img
                          class="selected"
                          :src="channelImage(pinterestSelectedProfile, type)"
                          alt=""
                          @error="
                            $event.target.src =
                              pinterestSelectedProfile?.username
                                ? fallbackImagePreview(
                                    pinterestSelectedProfile?.username[0],
                                  )
                                : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                          "
                        />
                      </div>
                    </span>
                    <span
                      class="
                        capitalize_text
                        text-left
                        inline-flex
                        flex-col
                        mx-0.5
                        font-bold
                        justify-start
                      "
                    >
                      {{ pinterestSelectedProfile?.username }}
                      <small>Profile</small>
                    </span>
                    <i class="icon-dropdown-cs ml-auto"></i>
                  </span>
                </template>
              </template>

              <div v-for="(account, i) in pinterestProfiles" :key="i">
                <b-dropdown-item
                  @click="selectAndUpdatePinterestProfile(account)"
                >
                  <div class="field_group">
                    <div class="checkbox_container">
                      <label>
                        <span class="dropdown-img-wrapper">
                          <img
                            class="selected"
                            :src="channelImage(account, type)"
                            alt=""
                            @error="
                              $event.target.src = account?.name
                                ? fallbackImagePreview(account?.name[0])
                                : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                            "
                          />
                          <img
                            :src="getSocialImageRounded('pinterest')"
                            alt=""
                            class="
                              absolute
                              -right-0.5
                              -bottom-0.5
                              !w-[1.125rem]
                              !h-[1.125rem]
                            "
                          />
                        </span>
                        <span class="dropdown-item-label">
                          <span v-b-tooltip.top="getAccountName(account)">{{
                            getAccountName(account)
                          }}</span>
                          <small>{{ getProfileType(type, account) }}</small>
                        </span>
                      </label>
                    </div>
                  </div>
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
        </template>
        <b-dropdown
          ref="dropdown"
          variant="studio-theme"
          toggle-class="analytic-accounts-dropdown__btn"
          :size="isPinterest ? 'md' : 'lg'"
          :disabled="updating || getAccountsList.length === 0"
          class="
            studio-theme-dropdown
            studio-theme-dropdown--checkbox
            studio-theme-dropdown--contain-image
            analytic-accounts-dropdown
          "
          :class="{
            '!min-w-[17rem]': isPinterest,
          }"
          no-caret
        >
          <template v-slot:button-content>
            <template v-if="getAccountsList.length === 0">
              <i class="fad fa-user-circle !text-2xl !leading-9 mr-2"></i>
              <span
                class="
                  capitalize_text
                  text-left
                  inline-flex
                  flex-col
                  mx-0.5
                  font-bold
                  justify-start
                "
              >
                <span class="font-normal"
                  >No {{ isPinterest ? 'Board' : 'Account' }} Connected</span
                >
              </span>
            </template>
            <template v-else>
              <span class="flex flex-row w-full justify-center items-center">
                <span class="connected-accounts-images">
                  <div>
                    <img
                      class="selected"
                      :src="channelImage(the_selected_account, type)"
                      alt=""
                      @error="
                        $event.target.src = the_selected_account?.name
                          ? fallbackImagePreview(the_selected_account?.name[0])
                          : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                      "
                    />
                  </div>
                </span>
                <span
                  v-if="isPinterest && the_selected_account?.type === 'Profile'"
                  class="
                    capitalize_text
                    text-left
                    inline-flex
                    flex-col
                    mx-0.5
                    font-bold
                    justify-start
                  "
                >
                  All Boards
                  <small>Board</small>
                </span>
                <span
                  v-else
                  class="
                    capitalize_text
                    text-left
                    inline-flex
                    flex-col
                    mx-0.5
                    font-bold
                    justify-start
                  "
                >
                  {{
                    the_selected_account.name ??
                    the_selected_account.platform_name
                  }}
                  <small> {{ isPinterest ? 'Board' : 'Connected' }}</small>
                </span>
                <i class="icon-dropdown-cs ml-auto"></i>
              </span>
            </template>
          </template>
          <div v-if="isPinterest">
            <b-dropdown-item @click="selectAllBoards">
              <div class="field_group">
                <div class="checkbox_container">
                  <label>
                    <span class="dropdown-img-wrapper">
                      <img
                        class="selected"
                        :src="channelImage(pinterestSelectedProfile, type)"
                        alt=""
                        @error="
                          $event.target.src = pinterestSelectedProfile?.username
                            ? fallbackImagePreview(
                                pinterestSelectedProfile?.username[0],
                              )
                            : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                        "
                      />
                      <img
                        :src="getSocialImageRounded(type)"
                        alt=""
                        class="
                          absolute
                          -right-0.5
                          -bottom-0.5
                          !w-[1.125rem]
                          !h-[1.125rem]
                        "
                      />
                    </span>
                    <span class="dropdown-item-label">
                      <span v-b-tooltip.top="'All Boards'">All Boards</span>
                    </span>
                  </label>
                </div>
              </div>
            </b-dropdown-item>
          </div>
          <div v-for="(account, i) in getAccountsList" :key="i">
            <b-dropdown-item
              v-b-tooltip="
                type === 'instagram'
                  ? !hasInstaPermission(account)
                    ? isIGPersonalAccount(account)
                      ? 'Analytics are not available for Instagram personal accounts.'
                      : 'Insufficient permissions, please reconnect profile to enable Analytics.'
                    : ''
                  : ''
              "
              :disabled="
                type === 'instagram' ? !hasInstaPermission(account) : false
              "
              @click="the_selected_account = account"
            >
              <div
                class="field_group"
                :class = "{
                  'flex items-center': isYoutube,
                }"
              >
                <div class="checkbox_container">
                  <label>
                    <span class="dropdown-img-wrapper">
                      <img
                        class="selected"
                        :src="channelImage(account, type)"
                        alt=""
                        @error="
                          $event.target.src = account?.name
                            ? fallbackImagePreview(account.name[0])
                            : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                        "
                      />
                      <img
                        :src="getSocialImageRounded(account.account_type)"
                        alt=""
                        class="
                          absolute
                          -right-0.5
                          -bottom-0.5
                          !w-[1.125rem]
                          !h-[1.125rem]
                        "
                      />
                    </span>
                    <span class="dropdown-item-label">
                      <span v-b-tooltip.top="getAccountName(account)">{{
                        getAccountName(account)
                      }}</span>
                      <small>{{ getProfileType(type, account) }}</small>
                    </span>
                  </label>
                </div>
                <img
                  v-if="isYoutube && account?.access_token?.scope?.split(' ').length < 7"
                  v-tooltip="{
                    content: tooltipHtml('To view your updated data, you must reconnect your YouTube account.'),
                    allowHTML: true,
                    theme: 'light',
                   }"
                  src="@assets/img/composer/warning-icon.svg"
                  alt=" "
                  class="w-5 h-5 mx-2 ml-auto"
                />
              </div>
            </b-dropdown-item>
          </div>
        </b-dropdown>
        <!-- Loader/ Spinner when toggle between accounts -->
        <clip-loader
          v-if="updating"
          class="spinner ml-3"
          :color="'#9da6ac'"
          :size="'16px'"
        ></clip-loader>
      </template>
    </div>
    <div class="analytics-filter-bar__right">
      <div class="analytics-filter-bar__right-btns">
        <template v-if="isOverview">
          <div class="ml-2 mr-2">
            <PlatformTooltip :type="type" />
          </div>
        </template>
        <template v-if="isTwitter">
          <div class="w-full text-nowrap mr-3">
            <p class="!shadow-xs text-gray-900 !text-sm">
              Last updated
              <b>
                {{ getLastUpdatedDate ? `on ${getLastUpdatedDate}` : '' }}
              </b>
            </p>
          </div>
        </template>
        <CstInputFields
          :date-options="dateOptions"
          :value="defaultDateRange"
          class="planner-date-picker cursor-pointer"
          :class="{
            disabled:
              getFetchSocialStatus || updating || getAccountsList.length === 0,
          }"
          placeholder="Select specific date range"
          size="small"
          type="date"
          :date-picker-popup-classes="['planner-date-picker-popup']"
          @change="changeDateRange"
        />
        <ExportButton
          :accounts="
            type === 'overview' ? getAccountsList : the_selected_account
          "
          :top-posts-limit="topPostsLimit"
          :start_date="analytics.startDate"
          :end_date="analytics.endDate"
          :type="type === 'overview' ? 'group' : type"
          :disabled="
            getFetchSocialStatus || updating || getAccountsList.length === 0
          "
        ></ExportButton>
      </div>
    </div>
  </div>
  <TwitterJobSettingsModal />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { analyticsDatePickerValues } from '@src/modules/analytics/components/common/helper'
import ExportButton from '@src/modules/analytics/views/common/ExportButton'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem.vue'
import TwitterJobSettingsModal from '@src/modules/analytics/views/twitter/components/TwitterJobSettingsModal.vue'
import SimpleCheckbox from '@src/modules/planner_v2/components/SimpleCheckbox'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import CstInputFields from '@ui/Input/CstInputFields'
import CstButton from '@ui/Button/CstButton.vue'
import * as _ from 'underscore'
import { EventBus } from '@common/lib/event-bus'
import useYoutubeAnalytics from '@src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import PlatformTooltip from '@src/modules/analytics/components/common/PlatformTooltip.vue'
import useDateFormat from '@common/composables/useDateFormat'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'
import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'
import usePinterestAnalytics from '@/src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'
import useTwitterAnalytics from '@/src/modules/analytics/views/twitter/composables/useTwitterAnalytics'

const { dateRange: tiktokDateRange } = useTiktokAnalytics()
const { dateRange: ytDateRange } = useYoutubeAnalytics()
const { dateRange: pinterestDateRange } = usePinterestAnalytics()
const {
  dateRange: twitterDateRange,
  platformSelected,
  filterJobData,
} = useTwitterAnalytics()
const { momentWrapper } = useDateFormat()
export default {
  name: 'AnalyticsFilterBar',
  components: {
    ExportButton,
    CstDropdown,
    CstDropdownItem,
    SimpleCheckbox,
    CstButton,
    CstInputFields,
    PlatformTooltip,
    TwitterJobSettingsModal,
  },
  mixins: [analyticsUtilsMixin, ComposerHelper],
  // eslint-disable-next-line vue/require-prop-types
  props: [
    'getAccounts',
    'getDate',
    'getPreviousDate',
    'updating',
    'type',
    'setProfileHasBoards',
  ],
  setup() {
    const {
      getSocialImageRounded,
      getPinterestBoardsByProfileId,
      getAllPinterestProfiles,
      tooltipHtml,
    } = useComposerHelper()
    return {
      getSocialImageRounded,
      getPinterestBoardsByProfileId,
      getAllPinterestProfiles,
      tooltipHtml,
    }
  },
  data() {
    return {
      all_selected: true,
      selected_accounts: [],
      localSelectedAccounts: [],
      the_selected_account: [],
      pinterestBoards: [],
      pinterestProfiles: [],
      pinterestSelectedProfile: {},
      all_accounts: [],
      analytics: analyticsDatePickerValues().analytics,
      searchQuery: '',
      searchedAccounts: [],
      topPostsLimit: 10,
      defaultDateRange: [
        momentWrapper().subtract(30, 'days').toDate(),
        momentWrapper().endOf('day').toDate(),
      ],
      dateRange: `${momentWrapper().subtract(
        30,
        'days'
      )} - ${momentWrapper().endOf('day')}`,
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters([
      'getActiveWorkspace',
      'getFetchSocialStatus',
      'getWorkspaces',
    ]),
    isTikTok() {
      return this.type === 'tiktok'
    },
    isYoutube() {
      return this.type === 'youtube'
    },
    isPinterest() {
      return this.type === 'pinterest'
    },
    isOverview() {
      return this.type === 'overview'
    },
    isTwitter() {
      return this.type === 'twitter'
    },
    dateOptions() {
      const allTimeYears = this.isYoutube ? 4 : 5
      return {
        defaultValue: this.dateRange,
        shortcuts: [
          {
            text: 'All Time',
            onClick() {
              const startDate = momentWrapper()
                .subtract(allTimeYears, 'years')
                .startOf('year')
                .toDate()
              const endDate = momentWrapper().endOf('day').toDate()
              return [startDate, endDate]
            },
          },
          {
            text: '24 hours',
            onClick() {
              const startDate = momentWrapper()
                .subtract(24, 'hours')
                .startOf('hours')
                .toDate()
              const endDate = momentWrapper().endOf('day').toDate()
              return [startDate, endDate]
            },
          },
          {
            text: '48 hours',
            onClick() {
              const startDate = momentWrapper()
                .subtract(48, 'hours')
                .startOf('hours')
                .toDate()
              const endDate = momentWrapper().endOf('day').toDate()
              return [startDate, endDate]
            },
          },
          {
            text: 'Last 3 days',
            onClick() {
              const startDate = momentWrapper()
                .subtract(3, 'days')
                .startOf('day')
                .toDate()
              const endDate = momentWrapper().endOf('day').toDate()
              return [startDate, endDate]
            },
          },
          {
            text: 'Last 7 Days',
            onClick() {
              const startDate = momentWrapper()
                .subtract(7, 'days')
                .startOf('day')
                .toDate()
              const endDate = momentWrapper().endOf('day').toDate()
              return [startDate, endDate]
            },
          },
          {
            text: 'Last 30 Days',
            onClick() {
              const startDate = momentWrapper()
                .subtract(30, 'days')
                .startOf('day')
                .toDate()
              const endDate = momentWrapper().endOf('day').toDate()
              return [startDate, endDate]
            },
          },
          {
            text: 'Last 3 Months',
            onClick() {
              const start = momentWrapper()
                .subtract(90, 'days')
                .startOf('day')
                .toDate()
              const end = momentWrapper().endOf('day').toDate()

              return [start, end]
            },
          },
          {
            text: 'Last 6 Months',
            onClick() {
              const start = momentWrapper()
                .subtract(180, 'days')
                .startOf('day')
                .toDate()
              const end = momentWrapper().endOf('day').toDate()

              return [start, end]
            },
          },
          {
            text: 'Last 1 Year',
            onClick() {
              const startDate = momentWrapper()
                .subtract(360, 'days')
                .startOf('day')
                .toDate()
              const endDate = momentWrapper().endOf('day').toDate()
              return [startDate, endDate]
            },
          },
          {
            text: 'Last 2 Years',
            onClick() {
              const startDate = momentWrapper()
                .subtract(720, 'days')
                .startOf('day')
                .toDate()
              const endDate = momentWrapper().endOf('day').toDate()
              return [startDate, endDate]
            },
          },
        ],
        disabledDate: (date) => date > new Date(),
        format: 'DD MMM YY',
      }
    },
    getAccountsList() {
      if (this.isPinterest) {
        return this.pinterestBoards
      } else {
        return this.selected_accounts
      }
    },
    getLastUpdatedDate() {
      return momentWrapper(
        this.the_selected_account?.last_analytics_updated_at
      ).formatTimezone().formatDateTime()
    },
  },
  watch: {
    the_selected_account(newVal, oldVal) {
      if (newVal !== oldVal) {
        platformSelected.value = newVal
        this.getAccounts(newVal)
        this.getDate(`${this.analytics.startDate} - ${this.analytics.endDate}`)
        this.getPreviousDate(
          `${this.previousAnalyticsPeriodDate(
            this.analytics.startDate,
            this.analytics.endDate
          )} - ${this.analytics.startDate}`
        )
        const hash = location.hash
        if ((this.isPinterest && newVal) || !this.isPinterest) {
          this.$nextTick(() => {
            this.$router.push({
              name: this.type + '_analytics_v3',
              params: {
                accountId: this.getRedirectPlatformId(),
              },
            })
          })
        }
        location.hash = hash
        filterJobData()
      }
    },
    selected_accounts(newVal, oldVal) {
      this.all_selected = newVal.length === this.selectAllAccounts().length
    },
    async getFetchSocialStatus(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === false) {
          this.getSelectedAccounts()
        }
      }
    },
    updating(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setAnalyzeService({ value: newVal })
      }
    },
    searchQuery(newVal, oldVal) {
      if (newVal !== oldVal && newVal.length > 0) {
        this.searchedAccounts = this.selectAllAccounts?.()?.filter((account) =>
          account.name?.toLowerCase().includes(this.searchQuery?.toLowerCase())
        )
        return
      }
      this.searchedAccounts = []
    },
  },
  created() {
    // Listen for root event
    EventBus.$on('workspace-changed', () => {
      this.getSelectedAccounts()
      this.handleApplyAccountSelection()
    })
    EventBus.$on('fetched-social-accounts', () => {
      this.getSelectedAccounts()
      this.handleApplyAccountSelection()
    })

  },
  mounted() {
    tiktokDateRange.value = this.defaultDateRange
    ytDateRange.value = this.defaultDateRange
    pinterestDateRange.value = this.defaultDateRange
    twitterDateRange.value = this.defaultDateRange
    this.getSelectedAccounts()
    this.handleApplyAccountSelection()
    this.$nextTick(() => {
      this.$root.$on('bv::dropdown::show', (bvEvent) => {
        this.closeDatePicker()
      })
    })
    EventBus.$on('set-top-posts-limit', (value) => {
      this.topPostsLimit = value
    })
    EventBus.$on('sync-twitter-data', (date) => {
      this.the_selected_account.last_analytics_updated_at = date
      this.isLoading = false
    })
  },
  beforeUnmount() {
    EventBus.$off('workspace-changed')
    EventBus.$off('set-top-posts-limit')
    EventBus.$off('sync-twitter-data')
  },
  methods: {
    ...mapActions([
      'fetchSocialService',
      'setAnalyzeService',
      'addToTwitterJobsTracker',
    ]),
    getAccountName(account) {
      return account?.name ?? account?.platform_name ?? account?.username ?? '-'
    },
    closeDatePicker() {
      if (this.$refs.instagram_analytics_dp) {
        this.$refs.instagram_analytics_dp.clickAway()
      }
    },
    getSelectedAccounts() {
      this.fetchSocialService({ value: true })
      if (this.type === 'overview') {
        this.all_selected = true
        const list = this.selectAllAccounts()
        this.localSelectedAccounts = this.selected_accounts =
          this.checkInstagramPermission(list)
      } else {
        const list = this.getPlatformAccounts(this.type)

        this.pinterestProfiles = this.getAllPinterestProfiles()
        this.pinterestSelectedProfile = this.pinterestProfiles[0]
        this.pinterestBoards = this.getPinterestBoardsByProfileId(
          this.pinterestSelectedProfile?.profile_id
        )

        this.localSelectedAccounts = this.selected_accounts =
          this.type === 'instagram' ? this.checkInstagramPermission(list) : list
        if (!this.$route.params.accountId) {
          if (this.type === 'pinterest') {
            this.the_selected_account = this.pinterestSelectedProfile
          } else {
            this.the_selected_account = this.selected_accounts[0]
          }
        } else {
          switch (this.type) {
            case 'facebook': {
              const account = this.findFacebookAccount(
                this.$route.params.accountId
              )
              if (account) {
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.selected_accounts[0]
              }
              break
            }
            case 'instagram': {
              const account = this.findInstaAccount(
                this.$route.params.accountId
              )
              if (account) {
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.selected_accounts[0]
              }
              break
            }
            case 'twitter': {
              const account = this.findTwitterAccount(
                this.$route.params.accountId
              )
              if (account) {
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.selected_accounts[0]
              }
              break
            }
            case 'linkedin': {
              const account = this.findLinkedinAccount(
                this.$route.params.accountId
              )
              if (account) {
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.selected_accounts[0]
              }
              break
            }
            case 'pinterest': {
              const account = this.findPinterestBoard(
                this.$route.params.accountId
              )
              if (account) {
                this.pinterestSelectedProfile = this.pinterestProfiles.find(
                  (profile) => profile.profile_id === account.profile_id
                )
                this.pinterestBoards = this.getPinterestBoardsByProfileId(
                  account.profile_id
                )
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.pinterestSelectedProfile
              }
              break
            }
            case 'tiktok': {
              const account = this.findTiktokAccount(
                this.$route.params.accountId
              )
              if (account) {
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.selected_accounts[0]
              }
              break
            }
            case 'youtube': {
              const account = this.findYoutubeAccount(
                this.$route.params?.accountId
              )
              if (account) {
                this.the_selected_account = account
              } else {
                this.the_selected_account = this.selected_accounts[0]
              }
              break
            }
          }
        }
      }

      if (this.isPinterest) {
        this.setProfileHasBoards(this.pinterestBoards.length > 0)
      }
      this.fetchSocialService({ value: false })
    },
    changeDateRange(values) {
      tiktokDateRange.value = values
      ytDateRange.value = values
      pinterestDateRange.value = values
      twitterDateRange.value = values

      this.analytics.isChanged = true
      // date range
      if (values[0] && values[1]) {
        const startDate = momentWrapper(values[0]).formatDateTime()
        const endDate = momentWrapper(values[1]).formatDateTime()
        this.dateRange = `${momentWrapper(startDate)} - ${momentWrapper(endDate)}`
        this.analytics.startDate = momentWrapper(values[0])
        this.analytics.endDate = momentWrapper(values[1])
      }
      this.getDate(`${this.analytics.startDate} - ${this.analytics.endDate}`)
      this.getPreviousDate(
        `${this.previousAnalyticsPeriodDate(
          this.analytics.startDate,
          this.analytics.endDate
        )} - ${this.analytics.startDate}`
      )
    },
    selectUnselectAll() {
      if (this.all_selected) {
        const list = this.selectAllAccounts()
        this.selected_accounts = this.checkInstagramPermission(list)
      } else {
        this.selected_accounts = []
      }
    },
    getAllAccountsLength() {
      return this.localSelectedAccounts?.length
    },
    checkInstagramPermission(list) {
      list.forEach((item) => {
        if (item.account_type === 'instagram') {
          if (!this.hasInstaPermission(item)) {
            list = _.without(
              list,
              _.findWhere(list, {
                instagram_id: item.instagram_id,
              })
            )
          }
        }
      })
      return list
    },
    hasInstaPermission(channel) {
      if (channel.account_type === 'instagram') {
        const analyticPermission = _.findWhere(channel.permissions, {
          permission: 'instagram_manage_insights',
        })
        return analyticPermission
          ? analyticPermission.status === 'granted'
          : false
      } else return true
    },
    handleApplyAccountSelection() {
      if (this.type === 'overview') {
        this.localSelectedAccounts = this.selected_accounts
        this.all_selected =
          this.selected_accounts.length === this.selectAllAccounts().length
        this.getAccounts(this.selected_accounts)
        this.getDate(`${this.analytics.startDate} - ${this.analytics.endDate}`)
        this.getPreviousDate(
          `${this.previousAnalyticsPeriodDate(
            this.analytics.startDate,
            this.analytics.endDate
          )} - ${this.analytics.startDate}`
        )
      }
    },
    getRedirectPlatformId() {
      if (!this.the_selected_account) return ''

      if (this.isTikTok || this.isYoutube) {
        return this.the_selected_account?.platform_identifier
      } else if (this.isPinterest) {
        return this.the_selected_account.type.toLowerCase() === 'profile'
          ? this.the_selected_account?.profile_id
          : this.the_selected_account?.board_id
      } else {
        return this.the_selected_account[this.type + '_id']
      }
    },
    selectAndUpdatePinterestProfile(profile) {
      this.pinterestSelectedProfile = profile
      this.pinterestBoards = this.getPinterestBoardsByProfileId(
        profile.profile_id
      )
      this.the_selected_account = profile

      this.setProfileHasBoards(this.pinterestBoards.length > 0)
    },
    selectAllBoards() {
      this.the_selected_account = this.pinterestSelectedProfile
    },
  },
}
</script>

<style lang="scss">
.analytics-filter-bar {
  background: #f4f6fa;
  z-index: 999;
  border-bottom: 0 solid #fff;
}

.analytic-accounts-dropdown {
  background-color: #fff;
  border-radius: 8px;
  min-width: 24rem;

  &__btn {
    flex: 0 1 auto !important;
    width: 100%;
  }

  .dropdown-menu {
    width: 100% !important;
    max-height: 28rem !important;
  }

  .dropdown-item-label {
    span {
      max-width: 16rem !important;
    }
  }
}

.mx-input-wrapper:hover {
  .mx-icon-clear {
    display: none !important;
  }

  .mx-icon-calendar {
    display: block !important;
  }
}
</style>
