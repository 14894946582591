<script setup>
import { computed, watch, defineProps } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/facebook_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import { isoCountries } from '@/src/modules/common/constants/iso-countries'
// state
const {
  overviewAudienceLocationData,
  dataZoomOptions,
  barChartOptions: chartOptions,
  isReportView,
  fanCount,

  routes,
  isLoadingStates,
} = useFacebookAnalytics('Audience')

const dataKeys = {
  country: 'audience_country',
  city: 'audience_city',
}

const props = defineProps({
  type: {
    type: String,
    default: 'Country',
    validator(value) {
      return ['country', 'city'].includes(value.toLowerCase())
    },
  },
})

const isNoData = computed(() => {
  return (
    Object.keys(
      overviewAudienceLocationData.value?.[dataKeys[props.type]] || {}
    ).length === 0
  )
})

const isCountry = computed(() => props.type.toLowerCase() === 'country')

const getTooltip = computed(() => {
  if (isCountry.value) {
    return "This graph displays the top countries from which your Facebook page's audience originates. The length of each bar represents the percentage of your audience that comes from that specific country."
  }
  return "This graph displays the top cities from which your Facebook page's audience originates. The length of each bar represents the percentage of your audience that comes from that specific city."
})

const countryToIso = computed(() => {
  const options =
    overviewAudienceLocationData.value?.[dataKeys[props.type]] || {}
  const countries = Object.keys(options).slice(0, 10)
  const countryToIso = {}
  countries.forEach((country) => {
    countryToIso[isoCountries[country]] = country
  })
  return countryToIso
})

const isLoading = computed(
  () => isLoadingStates.value?.[routes.OVERVIEW_AUDIENCE_LOCATION]
)

const getLabels = () => {
  const labels = {}
  const option = overviewAudienceLocationData.value?.[dataKeys.country] || {}
  Object.keys(option)?.forEach((item) => {
    labels[item] = `https://flagsapi.com/${item}/flat/64.png`
  })
  for (const item in labels) {
    labels[item] = {
      height: 20,
      width: 20,
      align: 'center',
      backgroundColor: {
        image: labels[item],
      },
    }
  }
  return labels
}

const title = computed(() => {
  if (isCountry.value) {
    return 'Countries'
  }
  return 'Cities'
})

watch(
  () => overviewAudienceLocationData.value,
  () => {
    const options =
      overviewAudienceLocationData.value?.[dataKeys[props.type]] || {}
    chartOptions.value.series[0].data =
      Object.values(options).slice(0, 10) || []
    chartOptions.value.xAxis.name = null

    chartOptions.value.xAxis.data = Object.keys(options).slice(0, 10).reverse()
    chartOptions.value.xAxis.axisLabel.formatter = null

    const yAxisFirstIndex = Array.isArray(chartOptions.value?.yAxis)
      ? chartOptions.value.yAxis[0]
      : chartOptions.value.yAxis
    const yAxisSecondIndex = Array.isArray(chartOptions.value?.yAxis)
      ? chartOptions.value.yAxis[1]
      : chartOptions.value.yAxis

    chartOptions.value.grid = {
      ...chartOptions.value.grid,
      right: 30,
    }
    chartOptions.value.xAxis = {
      ...chartOptions.value.xAxis,
      type: 'value',
      axisLabel: {
        show: false,
      },
      axisPointer: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: '#F2F4F6',
        },
      },
    }
    chartOptions.value.yAxis = [
      {
        ...yAxisFirstIndex,
        axisLabel: {
          ...yAxisFirstIndex.axisLabel,
          formatter: (value) => value,
        },
        type: 'category',
        name: '',
        axisPointer: {
          type: 'shadow',
        },
        data: Object.keys(options).slice(0, 10).reverse() || [],
      },
      {
        ...yAxisSecondIndex,
        name: '',
        type: 'category',
        position: 'right',
        axisLabel: {
          ...yAxisSecondIndex.axisLabel,
          formatter: (value) => (value !== 'null' ? value : '0'),
        },
        axisPointer: {
          type: 'shadow',
          label: {
            show: false,
          },
        },
        data: Object.values(options).slice(0, 10).reverse() || [],
      },
    ]

    if (isCountry.value) {
      const countries = Object.keys(options).slice(0, 10)
      chartOptions.value.yAxis[0].data = countries.map((country) => {
        return isoCountries[country] || country
      })
      chartOptions.value.yAxis[0].axisLabel = {
        ...chartOptions.value.yAxis[0].axisLabel,
        formatter: (value) => {
          return value + ' {' + countryToIso.value[value] + '| }'
        },
        rich: {
          ...getLabels(),
        },
      }
      chartOptions.value.tooltip.axisPointer.label.margin = 30
    }

    chartOptions.value.series[0] = {
      ...chartOptions.value.series[0],
      itemStyle: {},
      data: Object.values(options).slice(0, 10).reverse() || [],
    }
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper
    :type="`audience-location-${type}`"
    :enable-modal="true"
  >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Audience Top <span class="capitalize">{{ title }}</span>
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  {{ getTooltip }}
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  ...dataZoomOptions,
                  grid: { ...chartOptions.grid, bottom: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p v-if="fanCount <= 100"
            >You'll need 100 followers (who aren't your friends) to view this
            demographic information.</p
          >
          <p v-else>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
