<script setup>
import { computed } from 'vue'
import useNumber from '@common/composables/useNumber'
import trendUp from './svgs/trend-up.vue';
import TrendDown from './svgs/trend-down.vue';
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';

const { roundNumber } = useNumber()
const { tooltipHtml } = useComposerHelper()

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  showInfoTooltip: {
    type: Boolean,
    default: true,
  },
})

const trends = {
  UP: 'UP',
  DOWN: 'DOWN',
  NEUTRAL: 'NEUTRAL',
}

const toolTips = {
  totalNA:'No Data available for the selected date range. This metric will be measured from the date the account was added to ContentStudio.',
  netChange: ( title = '' ) => `The net increase or decrease in ${title} compared to the previous time period.`,
  percentageChange: ( title = '' ) => `The percentage rate of change in ${title} compared to the previous time period.`,
  netChangeNA : ( title = '' ) => `The net increase or decrease in ${title} compared to the previous time period. <p class="text-xs mt-2 opacity-70">No past data available for comparison to measure the magnitude of this change.</p>`,
  percentageChangeNA : ( title = '' ) => `The percentage rate of change in ${title} compared to the previous time period. <p class="text-xs mt-2 opacity-70">No past data available for comparison to measure the magnitude of this change.</p>`,
}

const trend = computed(() => {
  const data = props.data

  if (data.difference > 0) {
    return trends.UP
  } else if (data.difference < 0) {
    return trends.DOWN
  } else {
    return trends.NEUTRAL
  }
})

const growth = computed(() => {
  const data = props.data
  if(typeof data.growth === 'string') {
    return data.growth === 'N/A' ? data.growth : `${data.growth ?? '-'}%`
  }
  else{
    return data.growth === 'N/A' ? data.growth : `${roundNumber(data.growth) ?? '-'}%`
  }
})

const getStatisticClass = computed(() => {
  if (trend.value === trends.UP) {
    return 'text-[#3D8A61]'
  }

  if (trend.value === trends.DOWN) {
    return 'text-[#e02f2f]'
  }

  return 'text-[#69788E]'
})

</script>

<template>
  <div
    class="
      flex
      h-[7.25rem]
      w-full
      flex-col
      gap-2
      rounded-lg
      bg-white
      p-4
      shadow-[0px_3px_10px_0px_rgba(211,217,236,0.30)]
      color-border
      cst-editor
      justify-between
      group
    "
  >
    <div class="flex items-center justify-between gap-1">
      <div class="flex items-center gap-1 -mt-2" >
        <p class="text-sm text-[#757A8A]">{{ data.title ?? '-' }}</p>
        <div class="opacity-0 group-hover:!opacity-100 transition-all">
          <v-menu
            v-if="showInfoTooltip"
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
          >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
                {{data?.tooltip}}
            </p>
          </template>
          </v-menu>
        </div>
      </div>
      <template v-if="data?.icon">
        <img :src = "data?.icon" alt="card icon" class="scale-[0.8] 2xl:scale-90" />
      </template>
      <template v-else>
        <div class="w-[2.67rem] h-[2.67rem] bg-transparent"></div>
      </template>
    </div>
    <div class="flex items-end justify-between">
      <div>
        <span
          v-tooltip="{
            content: data?.total === 'N/A' ? tooltipHtml(toolTips?.totalNA) : tooltipHtml(data?.originalValue),
            theme: 'light',
            allowHTML: true,
            }"
            class="text-2xl font-medium text-black w-max">
            {{ data.total ?? '-' }}
        </span>
      </div>
      <div class="flex items-center gap-1">
        <div v-if="data?.showPositiveNegative" class="flex gap-1 mr-2">
          <div class="px-2 py-0.5 rounded-full bg-[#EEF9F3] text-[#3D8A61] text-xs font-semibold flex items-center justify-between">
            <span class="font-normal">+ve</span>&nbsp;{{ data?.positive }}
          </div>
          <div class="px-2 py-0.5 rounded-full bg-[#FFECF0] text-[#e02f2f] text-xs font-semibold flex items-center justify-between">
            <span class="font-normal">-ve</span>&nbsp;{{ data?.negative }}
          </div>
        </div>
        <div
          v-tooltip="{
            content: data.growth === 'N/A' ? tooltipHtml(toolTips.percentageChangeNA(data?.title)) : tooltipHtml(toolTips.percentageChange(data?.title)),
            theme: 'light',
            allowHTML: true,
            }"
          class="font-sm font-medium py-0.5 rounded-full flex items-center gap-1"
          :class="[getStatisticClass,data.growth === 'N/A' && '!bg-[#fafafa] !text-[#69788E]']"
        >
          <div v-if="trend === trends.UP && data.growth !== 'N/A'">
            <trendUp class="w-2 h-3" />
          </div>
          <div v-if="trend === trends.DOWN && data.growth !== 'N/A'">
            <TrendDown class="w-2 h-3" />
          </div>
          {{ growth }}
        </div>

      </div>
    </div>
  </div>
</template>
