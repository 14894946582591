import { computed, ref } from 'vue'
import moment from 'moment-timezone'
import { useStore } from '@state/base'

const { getters } = useStore()

const getAccountDateFormat = ref(getters?.getProfile?.date_format)
/**
 * Provides utility functions for date and time formatting based on user preferences.
 * Utilizes the `moment` and `moment-timezone` libraries to parse and format dates and times.
 *
 * @returns {Object} An object containing methods for date and time formatting.
 */
export default function useDateFormat() {
  /**
   * Computes the date format string based on the user's time format preference (12h or 24h).
   *
   * @returns {String} The computed date format string.
   */
  const getAccountDateTimeFormat = computed(() => {
    return getters?.getProfile?.time_format === '12h'
      ? `${getters?.getProfile?.date_format} hh:mm A`
      : `${getters?.getProfile?.date_format} HH:mm`
  })

  /**
   * Computes the user's time format preference.
   *
   * @returns {String} The user's time format preference ('12h' or '24h').
   */
  const getUserTimePreference = computed(() => {
    return getters?.getProfile?.time_format
  })

  /**
   * Parses a time string according to the user's time format preference.
   *
   * @param {String} timeString - The time string to parse.
   * @returns {moment} A moment object representing the parsed time.
   */
  const parseTime = (timeString) => {
    if (getUserTimePreference.value === '12h') {
      return moment(timeString, 'hh:mm A')
    } else {
      return moment(timeString, 'HH:mm')
    }
  }

  /**
   * Custom wrapper for the `moment` function to allow chaining and formatting.
   * Adds custom methods to the moment object for formatting dates and times according to user preferences.
   *
   * @param {String|moment} dateOrTime - The date or time to format, either as a string or a moment object.
   * @param {Boolean} formatStringTime - If true, treats `dateOrTime` as a time string to be parsed.
   * @returns {moment} A moment object with custom formatting methods.
   */
  const momentWrapper = (dateOrTime, formatStringTime = false) => {
    let momentInstance
    if (formatStringTime) {
      momentInstance = parseTime(dateOrTime)
    } else {
      momentInstance = dateOrTime ? moment(dateOrTime) : moment()
    }

    /**
     * Formats the date according to the user's account date format.
     *
     * @returns {String} The formatted date string.
     */
    momentInstance.formatDateTime = function () {
      return this.format(getAccountDateTimeFormat.value)
    }

    /**
     * Formats the time according to the user's time preference.
     *
     * @returns {String} The formatted time string.
     */
    momentInstance.formatTime = function () {
      return this.format(
        getUserTimePreference.value === '12h' ? 'hh:mm A' : 'HH:mm'
      )
    }

    /**
     * Formats the date according to the user's date format preference.
     *
     * @returns {String} The formatted date string.
     */
    momentInstance.formatDate = function () {
      return this.format(getters?.getProfile?.date_format)
    }

    /**
     * Formats the timezone according to the user's timezone preference.
     */
    momentInstance.formatTimezone = function () {
      return this.tz(getters?.getActiveWorkspace?.timezone)
    }

    // Sets the timezone for the moment instance to the active workspace timezone.
    return momentInstance
  }

  return {
    momentWrapper,
    getAccountDateFormat,
    getUserTimePreference,
    getAccountDateTimeFormat,
  }
}
