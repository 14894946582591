<template>
  <div class="h-screen overflow-x-hidden">
    <div class="grid grid-cols-8 mx-auto h-full">
      <!-- Auth form -->
      <div class="col-span-3">
        <div class="auth-form-container">
          <form class="auth-form">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <h2 class="text-left">Create Accountss</h2>
              <p class="text-sm text-[#CFCECE] italic !mt-0">No credit card required</p>
            </div>

            <div class="flex w-full justify-center mb-6 gap-3">

                <button
                v-tooltip="'Sign up with Facebook'"
                type="button"
                class="
                  transition ease-in-out delay-150
                  text-gray-900
                  hover:!text-[#0068E5]
                  bg-white
                  border border-gray-200
                  hover:!border-[#d2e3fc]
                  focus:!ring-4
                  focus:!outline-none
                  focus:!ring-gray-100
                  font-medium
                  rounded-lg
                  text-sm
                  p-2.5
                  text-center
                  inline-flex
                  items-center
                  justify-center
                  mr-2
                  w-[40px]"
                  data-cy="facebook"
                  :disabled="!getSSOAuthorizationLinks.facebook"
                @click.prevent="
                  redirectSSO(getSSOAuthorizationLinks.facebook, 'signed_up')
                ">
                <span class="flex align-items-center gap-2">
                  <img src="@assets/img/icons/facebook.svg" class="w-[18px] h-[18px]"  alt=""/>
                </span>
                </button>

                <button
                  v-tooltip="'Sign up with X (formerly Twitter)'"
                  type="button"
                  class="
                  transition ease-in-out delay-150
                  text-gray-900
                  bg-white
                  hover:!text-[#0068E5]
                  border border-gray-200
                  hover:!border-[#d2e3fc]
                  focus:!ring-4
                  focus:!outline-none
                  focus:!ring-gray-100
                  font-medium
                  rounded-lg
                  text-sm
                  p-2.5
                  text-center
                  inline-flex
                  items-center
                  justify-center
                  mr-2
                  w-[40px]"
                  data-cy="twitter"
                  :disabled="!getSSOAuthorizationLinks.twitter"
                  @click.prevent="
                    redirectSSO(getSSOAuthorizationLinks.twitter, 'signed_up')
                  ">
                  <span class="flex align-items-center gap-3">
                    <img src="@assets/img/icons/twitter-x-rounded.svg" class="w-[18px] h-[18px]"  alt=""/>
                  </span>
                </button>

              <GoogleSignin v-tooltip="'Sign up with Google'" ></GoogleSignin>
            </div>

            <div class="w-full text-center leading-[0.1rem] mb-[2rem] custom-border">
              <span class="bg-white px-[0.625rem] text-[#CFCECE]">or</span>
            </div>

            <div class="flex flex-col gap-2">

              <!-- First & Last Name-->
              <div class="form-row">
                <div class="col">
                  <CstFloatingLabelInput
                    id="fname"
                    v-model="account.firstname"
                    class="!h-[3.275rem]"
                    type="text"
                    label="First Name"
                    :maxlength="18"
                    show-icon-left
                   value="account.firstname">
                    <template v-slot:icon>
                      <i class="icon-User"></i>
                    </template>
                  </CstFloatingLabelInput>
                </div>

                <div class="col">
                  <CstFloatingLabelInput
                    id="lname"
                    v-model="account.lastname"
                    class="!h-[3.21rem]"
                    type="text"
                    label="Last Name"
                    :maxlength="18"
                    show-icon-left
                   value="account.lastname">
                    <template v-slot:icon>
                      <i class="icon-User"></i>
                    </template>
                  </CstFloatingLabelInput>
                </div>

              </div>

              <!-- Email -->
              <CstFloatingLabelInput
                id="email"
                v-model="account.email"
                class="!h-[3.275rem]"
                type="email"
                label="Email Address"
                show-icon-left
               value="account.email">
                <template v-slot:icon>
                  <i class="icon-Email"></i>
                </template>
              </CstFloatingLabelInput>

              <!-- Password -->
              <div>
              <CstFloatingLabelInput
                id="password"
                v-model="account.password"
                class="!h-[3.275rem]"
                type="password"
                label="Password"
                :maxlength="18"
                show-icon-left
                value="account.password">
                <template v-slot:icon>
                  <i class="icon-Password"></i>
                </template>
              </CstFloatingLabelInput>

                <div v-if="password_strength_message" class="simple-form__text-field-message flex items-center justify-between mx-1 relative top-[1.11rem]" :class="{ danger: password_state === 'danger' }">
                  <p
                      class="text-xs"
                  >
                    {{ password_strength_message }}
                  </p>
                  <div class="flex gap-1">
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-red-500': password_state === 'weak', ' !bg-green-500': password_state === 'strong', '!bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong', 'bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong' }"></div>
                  </div>
                </div>
              </div>

              <!-- Business Name -->
              <CstFloatingLabelInput
                id="bName"
                v-model="account.business_name"
                class="!h-[3.275rem]"
                type="text"
                label="Business Name"
                show-icon-left
               value="account.business_name">
                <template v-slot:icon>
                  <i class="far fa-briefcase"></i>
                </template>
              </CstFloatingLabelInput>

              <!-- Business Type -->

              <div
                class="
                  simple-form__select-field
                  flex
                  items-center
                  h-[3.275rem]
                  rounded-md
                  border
                  bg-white
                  text-gray-900 text-sm
                  focus-within:outline-blue
                  w-full
                  py-2.5
                  px-0
                "
              >
                <select
                  v-model="account.business_type"
                  class="!px-3"
                  required
                  data-cy="business_type"
                >
                  <option disabled selected value=""
                    >What best describes you?</option
                  >
                  onkeypress="return
                  /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                  <option value="blogger">Blogger</option>
                  <option value="solopreneur">Solopreneur</option>
                  <option value="agency">Agency</option>
                  <option value="saas_business">SaaS Business</option>
                  <option value="marketing_team">Marketing Team</option>
                  <option value="enterprise">Enterprise</option>
                </select>
              </div>

              <!-- Phone Number -->
              <div ref="phoneField" class="auth-form__phone-field">
                <VueTelInput
                  :value="account.phone_no"
                  data-cy="telephone"
                  mode="international"
                  placeholder="Phone Number"
                  :show-dial-code-in-list="true"
                  :show-dial-code-in-selection="false"
                  :valid-characters-only="true"
                  @input="phoneInput"
                  @validate="phoneValidate"
                >
                  <template v-slot:arrow-icon="open">
                    <i v-if="open" class="fas fa-chevron-up"></i>
                    <i v-else class="fas fa-chevron-down"></i>
                  </template>
                </VueTelInput>
              </div>

              <!-- Google Recaptcha-->
              <vueRecaptcha
                    ref="recaptcha"
                    :sitekey="getGoogleRecaptchaKey"
                    size="invisible"
                    theme="light"
                    loading-timeout="30000"
                    @verify="createAccount"
                    @expire="onCaptchaExpired"
                    @fail="onCaptchaExpired"
                    @error="onCaptchaExpired">
              </vueRecaptcha>

            </div>
            <!-- Terms & Conditions -->
            <div class="auth-form__terms">
              <p>
                By signing up, you agree to our
                <a
                    href="https://contentstudio.io/terms-and-conditions"
                    target="_blank">terms of service</a>
                and
                <a
                    href="https://contentstudio.io/privacy-policy"
                    target="_blank"
                >privacy policy.</a
                >
              </p>
            </div>
            <div class="auth-form__button-field">
              <button
                  :disabled="registerLoader"
                  data-cy="sign_up_button"
                  class="btn btn-studio-theme-space btn-size-large"
                  @click.prevent="registerUser"
              >
                <span>Create Account</span>

                <img
                    v-if="registerLoader"
                    style="width: 20px; margin-left: 8px"
                    src="../assets/img/common/gif_loader_white.gif"
                    alt=""
                />
              </button>
            </div>
          </form>

          <div class="auth-form-container__footer">
            <p>
              Already have an account?
              <router-link :to="{ name: 'login' }"><b>Sign In</b></router-link>
            </p>
          </div>
        </div>
      </div>

      <!-- Auth slides -->
      <div class="col-span-5 h-full">
        <NewFeaturesSlider :show-logo="false">
            <template v-slot:slides>
              <Slide>
                <div
                  class="
                    flex flex-col
                    items-center
                    justify-center
                    text-center
                    h-full
                    w-full
                    p-9
                  "
                >
                  <div class="text-left max-w-3xl 2xl:mr-8">
                    <div class="mx-auto mb-10">
                      <LogoComponent />
                    </div>
                    <h2 class="text-2xl font-bold mb-4"
                      >Dashboard</h2
                    >
                    <p class="text-base mb-4"
                      >Step into a content management revolution with our advanced dashboard.
                      With its intuitive design and user-friendly layout, the dashboard empowers you to streamline,
                      curate, and schedule your content creation effortlessly while managing your day-to-day operations.</p
                    >
                  </div>
                  <div class="feature-img-container w-full">
                    <img
                      class="w-full"
                      draggable="false"
                      src="@assets/img/new-features/dashboard.png"
                      alt="Dashboard"
                    />
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="
                    flex flex-col
                    items-center
                    justify-center
                    text-center
                    h-full
                    p-9
                  "
                >
                  <div class="text-left max-w-3xl 2xl:mr-8">
                    <div class="mx-auto mb-10">
                      <LogoComponent />
                    </div>
                    <h2 class="text-2xl font-bold mb-4">Social Media Publishing & Scheduling</h2>
                    <p class="text-base mb-4">
                      Effortlessly turn ideas into captivating social posts and grow your brand’s presence. Craft,
                      schedule, and share compelling content with ease using our visual multi-channel calendar.
                      Plan smarter and connect deeper with ContentStudio.io.
                    </p>
                  </div>
                  <div class="feature-img-container w-full">
                    <img
                      class="w-full"
                      draggable="false"
                      src="@assets/img/new-features/planner.png"
                      alt="Social Media Publishing & Scheduling"
                    />
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="
                    flex flex-col
                    items-center
                    justify-center
                    text-center
                    h-full
                    p-9
                  "
                >
                  <div class="text-left max-w-3xl 2xl:mr-8">
                    <div class="mx-auto mb-10">
                      <LogoComponent />
                    </div>
                    <h2 class="text-2xl font-bold mb-4"
                      >Advanced Analytics</h2
                    >
                    <p class="text-base mb-4"
                      >Make informed, data-driven decisions with elegance and ease. Dive into actionable insights,
                      understand audience dynamics, and watch engagement soar with ContentStudio's beautifully crafted
                      reports, ready for team collaboration and client presentations.</p
                    >
                  </div>
                  <div class="feature-img-container w-full">
                    <img
                      class="w-full"
                      draggable="false"
                      src="@assets/img/new-features/analytics.png"
                      alt="Advanced Analytics"
                    />
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="
                    flex flex-col
                    items-center
                    justify-center
                    text-center
                    h-full
                    p-9
                  "
                >
                  <div class="text-left max-w-3xl 2xl:mr-8">
                    <div class="mx-auto mb-10">
                      <LogoComponent />
                    </div>
                    <h2 class="text-2xl font-bold mb-4"
                      >Unified Inbox</h2
                    >
                    <p class="text-base mb-4"
                      >With ContentStudio's streamlined communication hub, stay connected with your audience, build stronger relationships, and maintain complete control over your brand's social conversations.</p
                    >
                  </div>
                  <div class="feature-img-container w-full">
                    <img
                      class="w-full"
                      draggable="false"
                      src="@assets/img/new-features/inbox.png"
                      alt="Unified Inbox"
                    />
                  </div>
                </div>
              </Slide>
              <Slide>
                <div
                  class="
                    flex flex-col
                    items-center
                    justify-center
                    text-center
                    h-full
                    p-9
                  "
                >
                  <div class="text-left max-w-3xl 2xl:mr-8">
                    <div class="mx-auto mb-10">
                      <LogoComponent />
                    </div>
                    <h2 class="text-2xl font-bold mb-4"
                      >AI-Powered Writing Assistant</h2
                    >
                    <p class="text-base mb-4"
                      >It's time to revolutionize content creation by reducing errors, saving time, and boosting creativity with ContentStudio's AI assistant. Start exploring new, endless possibilities in content marketing now!</p
                    >
                  </div>
                  <div class="feature-img-container w-full">
                    <img
                      class="w-full"
                      draggable="false"
                      src="@assets/img/new-features/chat.png"
                      alt="AI-Powered Writing Assistant"
                    />
                  </div>
                </div>
              </Slide>
            </template>
        </NewFeaturesSlider>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {VueTelInput} from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import {registerURL} from '@src/config/api-utils'
import {authenticationTypes} from '@src/store/mutation-types'
import VueCookie from 'vue-cookie'
import vueRecaptcha from 'vue3-recaptcha2';
import NewFeaturesSlider from '@src/modules/account/components/NewFeaturesSlider.vue'
import { Slide } from '@jambonn/vue-concise-carousel'
const CstFloatingLabelInput = () => import('@ui/Input/CstFloatingLabelInput')
import {workspaceTypes} from "@src/modules/setting/store/states/mutation-types";
import useTime from "@/src/modules/common/composables/useTime"
import useIp from "@/src/modules/common/composables/useIp"
const GoogleSignin = () => import("@src/modules/account/components/GoogleSignin.vue")
const LogoComponent = () => import("@src/modules/account/components/LogoComponent.vue")
export default {
  name: 'SignUp',
  components: {
    NewFeaturesSlider,
    Slide,
    VueTelInput,
    vueRecaptcha,
    CstFloatingLabelInput,
    GoogleSignin,
    LogoComponent
  },
  setup() {
    const { getClientTimeZone } = useTime()
    const { ipv4 } = useIp()

    return {
      getClientTimeZone,
      ipv4
    }
  },
  data() {
    return {
      account: {
        firstname: '',
        lastname: '',
        email: this.$route.query.email ? this.$route.query.email : '',
        password: '',
        plan: 'trial',
        business_type: '',
        business_name: '',
        phone_no: '',
        timezone: this.getClientTimeZone(),
      },
      isValidPhone: false,
      registerLoader: false,
      password_strength_message: '',
      password_state: '',
    }
  },
  computed: {
    ...mapGetters(['getSSOAuthorizationLinks']),
    getGoogleRecaptchaKey() {
      return process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY
    },
  },
  watch: {
    'account.password'(value) {
      if (value) {
        if (value.length === 0) {
          this.password_strength_message = ''
          this.password_state = ''
        }
        if (value.length < 12) {
          this.password_strength_message = 'Password must be 12 characters or more';
          this.password_state = 'danger';
        } else if (value.length < 14) {
          this.password_strength_message = 'Password could be more secure';
          this.password_state = 'weak';
        } else if (value.length < 16) {
          this.password_strength_message = 'Password is okay';
          this.password_state = 'fair';
        } else {
          this.password_strength_message = 'Password is strong';
          this.password_state = 'strong';
        }
      } else {
        this.password_strength_message = ''
        this.password_state = ''
      }
    },
  },
  created() {
    this.fetchSSOLinks()
    if (this.$route.query && typeof this.$route.query.package !== 'undefined') {
      this.account.plan = this.$route.query.package
    }
  },
  mounted() {
    console.debug('Email', this.$route.query.email)
    this.trackUserMaven('pageview')
    const phoneFieldWidth = this.$refs.phoneField.offsetWidth
    const sheet = document.createElement('style')
    sheet.innerHTML = `.vti__responsive-width {width: ${phoneFieldWidth}px`
    document.body.appendChild(sheet)
    this.$refs.phoneField
      .getElementsByClassName('vti__dropdown-list')[0]
      .classList.add('vti__responsive-width')
  },
  methods: {
    ...mapActions(['fetchProfile']),
    onCaptchaExpired() {
      this.registerLoader = false
      this.$refs.recaptcha.reset()
    },

    registerUser() {
      if (!this.account.firstname) {
        this.alertMessage('Please enter your first name', 'error')
        return
      }
      if (!this.account.lastname) {
        this.alertMessage('Please enter your last name', 'error')
        return
      }
      if (!this.account.email) {
        this.alertMessage('Please enter an email address', 'error')
        return
      }
      if (
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.account.email.toLowerCase()
        )
      ) {
        this.alertMessage('Please enter a valid email address', 'error')
        return
      }
      if (!this.account.password) {
        this.alertMessage('Please enter a password', 'error')
        return
      }
      if (this.password_state === 'danger') {
        this.alertMessage(this.password_strength_message, 'error')
        return
      }
      if (!this.account.business_name) {
        this.alertMessage('Please enter a business name', 'error')
        return
      }
      const regexp = /^[\p{L} .0-9]+$/u
      if (!regexp.test(this.account.business_name)) {
        this.alertMessage('Please Enter a Valid Business name, (may include letters and numbers)', 'error')
        return
      }
      if (!this.account.business_type) {
        this.alertMessage('Please select a business type', 'error')
        return
      }
      this.$refs.recaptcha.execute()
    },

    phoneInput(str, { number, isValid, country }) {
      const cleanPhoneNumber = this.account.phone_no?.replace(/\s/g, '');
      const cleanDialCode = '+' + country.dialCode?.replace(/\s/g, '');

      if (!cleanPhoneNumber?.startsWith(cleanDialCode)) {
        // If the cleaned phone number doesn't start with the cleaned dial code, update the phone number
        this.account.phone_no = '+' + country.dialCode;
        return;
      }

      // Check if the selected text is the full phone number including the country code
      const selectedTextIsFullNumber = str === '+' + country.dialCode + cleanPhoneNumber;

      // Update the phone number only if the selected text is not the full phone number
      if (!selectedTextIsFullNumber) {
        this.account.phone_no = str;
      }

      const regex = /^[+\d]?[\d.\s()]*$/

      if (!regex.test(document.querySelector('.vti__input')?.value) || str === '') {
        this.isValidPhone = false;
        document.querySelector('.vti__input').value = this.account.phone_no
      } else {
        this.isValidPhone = isValid;
        this.account.phone_no = document.querySelector('.vti__input').value = str;
      }

      this.phoneValidate({ number, isValid, country })
    },

    phoneValidate({ number, isValid }) {
      this.isValidPhone = isValid
      // eslint-disable-next-line no-useless-escape
      const regex = /^[+\d]?[\d.\s()]*$/
      if (!regex.test(number)) {
        this.isValidPhone = false
      }
    },


    async createAccount(recaptchaToken) {
      // console.debug('here', VueCookie.get('_fprom_tid', { domain: '.contentstudio.io' }))
      if (this.password_state !== 'danger') {
        this.registerLoader = true
        // this.account['fp_ref_id'] = VueCookie.get('_fprom_tid')
        this.account.fp_tid = VueCookie.get('_fprom_tid')
        this.account.captcha_code = recaptchaToken

        // if (this.$route.query.fpr) {
        //   this.account['fp_ref_id'] = this.$route.query.fpr
        // }

        try {
          this.$refs.recaptcha.reset()
        } catch (e) {
          console.error(e)
        }

        const response = await this.$http
            .post(registerURL, {...this.account, ip: this.ipv4} )
            .then((response) => {
              if (response?.data?.status) {
                this.$store.commit(
                    authenticationTypes.SET_JWT_TOKEN,
                    response?.data?.token
                )
                this.$store.commit(
                    authenticationTypes.SET_LOGGED_USER,
                    response?.data?.logged_user
                )
                this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
              } else {
              this.registerLoader = false
              this.alertMessage(response?.data?.message, 'error')
            }
            return response
          })
          .catch((response) => {
            this.registerLoader = false
            this.alertMessage(
              'Something went wrong! Please try again.',
              'error'
            )
            return response
          })

        if (response && response.data.status) {
          await this.fetchProfile()
          this.$store.commit(workspaceTypes.SET_ACTIVE_WORKSPACE, null)
          // await this.Plan()
          // await this.identify()

          if(this.$store.getters?.getProfile?.email_verify) {
            gtag('event', 'account_sign_up', {
              'send_to': process.env.VUE_APP_GTAG_SEND_TO,
              'email' : this.$store.getters?.getProfile?.email,
            });
          }

          await this.trackSignUpEvent()

          // this.$store.dispatch('trackEvent', { event: 'signed_up' })
          this.fetchWorkspaces()

          // await this.trackUserMaven('signed_up')

          this.$router.push({ name: 'email_verification' })
        }
        this.registerLoader = false
      } else {
        this.registerLoader = false
        this.alertMessage(this.password_strength_message, 'error')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .vti__dropdown{
  width: 64px;
}

::v-deep .vti__dropdown,
::v-deep .vti__input {
  height: 3.275rem !important
}

::v-deep .VueCarousel-pagination {
  justify-content: center;
  margin-bottom: 1rem;
}


@media screen and (max-width: 1472px) {
  .auth-buttons {
    flex-direction: column;

    span {
      width: 100%;
      justify-content: center;
    }
  }
}

.feature-img-container {
  max-width: 66rem !important;
}

.auth-form-container {
  border-radius: 0 !important;
  padding: 4rem 5.4rem !important;

  .auth-form__social-field {
    margin-top: 2.3rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .custom-border {
    border-bottom: 1px solid #cfcece92;
    border-radius: 50%;
  }
}

</style>
