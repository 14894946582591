<template>
  <div class="h-screen overflow-x-hidden">
    <div class="grid grid-cols-8 mx-auto h-full">
      <!-- Auth form -->
      <div class="col-span-3">
        <div class="auth-form-container">
          <form class="auth-form simple-form">
            <div class="auth-form__heading auth-form__heading--center">
              <h2 class="flex justify-content-center">Welcome Back</h2>
            </div>

            <div class="flex w-full justify-center mb-6 gap-3">
              <button
                v-tooltip="'Sign in with Facebook'"
                type="button"
                class="transition ease-in-out delay-150 text-gray-900 hover:!text-[#0068E5] bg-white border border-gray-200 hover:!border-[#d2e3fc] focus:!ring-4 focus:!outline-none focus:!ring-gray-100 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center justify-center mr-2 w-[40px]"
                data-cy="facebook"
                :disabled="!getSSOAuthorizationLinks.facebook"
                @click.prevent="
                  redirectSSO(getSSOAuthorizationLinks.facebook, 'logged_in')
                "
              >
                <span class="flex align-items-center gap-2">
                  <img
                    src="@assets/img/icons/facebook.svg"
                    class="w-[18px] h-[18px]"
                    alt=""
                  />
                </span>
              </button>

              <button
                v-tooltip="'Sign in with X (formerly Twitter)'"
                name="twitter"
                type="button"
                class="transition ease-in-out delay-150 text-gray-900 hover:!text-[#0068E5] bg-white border border-gray-200 hover:!border-[#d2e3fc] focus:!ring-4 focus:!outline-none focus:!ring-gray-100 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center justify-center mr-2 w-[40px]"
                data-cy="twitter"
                :disabled="!getSSOAuthorizationLinks.twitter"
                @click.prevent="
                  redirectSSO(getSSOAuthorizationLinks.twitter, 'logged_in')
                "
              >
                <span class="flex align-items-center gap-3">
                  <img
                    src="@assets/img/icons/twitter-x-rounded.svg"
                    class="w-[18px] h-[18px]"
                    alt="twitter"
                  />
                </span>
              </button>
              <GoogleSignin v-tooltip="'Sign in with Google'"></GoogleSignin>
            </div>

            <div
              class="w-full text-center leading-[0.1rem] mb-[2rem] custom-border"
            >
              <span class="bg-white px-[0.625rem] text-[#CFCECE]">or</span>
            </div>

            <CstFloatingLabelInput
              id="email"
              v-model="email"
              class="mb-6"
              type="email"
              label="Email Address"
              show-icon-left
              value="email"
            >
              <template v-slot:icon>
                <i class="icon-Email"></i>
              </template>
            </CstFloatingLabelInput>

            <CstFloatingLabelInput
              id="password"
              v-model="password"
              class="mb-6"
              type="password"
              label="Password"
              show-icon-left
              value="password"
            >
              <template v-slot:icon>
                <i class="icon-Password"></i>
              </template>
            </CstFloatingLabelInput>

            <div class="simple-form__check-field">
              <div class="field_group">
                <div class="checkbox_container">
                  <label for="rememberCheck">
                    Remember me
                    <input
                      id="rememberCheck"
                      v-model="remember_me"
                      type="checkbox"
                      checked="checked"
                    />
                    <span class="check"></span>
                  </label>
                </div>
              </div>

              <router-link :to="{ name: 'forgotpassword' }"
                >Forgot Password?
              </router-link>
            </div>
            <div class="auth-form__button-field">
              <button
                id="sign-in"
                name="sign-in"
                data-cy="sign-in"
                :disabled="loginLoader"
                class="btn btn-studio-theme-space btn-size-large"
                @click.prevent="loginAccount"
              >
                <span>
                  <template v-if="loginLoader">Processing</template>
                  <template v-else>Sign in</template>
                </span>
                <img
                  v-if="loginLoader"
                  style="width: 20px; margin-left: 8px"
                  src="../assets/img/common/gif_loader_white.gif"
                  alt=""
                />
              </button>
            </div>
          </form>

          <div class="auth-form-container__footer">
            <p
              >Just approving content?
              <router-link :to="{ name: 'magicLogin' }"
                >Sign in without a password
              </router-link>
            </p>
            <p
              >Don’t have an account?
              <router-link :to="{ name: 'signup' }"
                ><b data-cy="sign-up">Sign Up</b></router-link
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-span-5 h-full">
        <div
          class="flex flex-col items-center justify-center text-center h-full p-9"
        >
          <div class="flex flex-col items-center max-w-3xl mb-12">
            <p class="font-bold mb-5 text-4xl">September 2024 Product Updates</p>
            <div class="bg-[#26303E] py-2 px-6 text-white rounded-lg w-max">
              <p
                class="font-bold flex items-center justify-center gap-2 text-xl"
                ><i class="far fa-crown text-google !text-sm"></i> Exciting New
                Features!</p
              >
            </div>
          </div>
          <img
            class="w-full object-contain max-h-[36rem]"
            draggable="false"
            src="@assets/img/new-features/product-updates.png"
            alt="Aug 2024 Product Updates"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { authenticationTypes } from '@src/store/mutation-types'
import { mapActions, mapGetters } from 'vuex'
import { login } from '@src/modules/account/services'
const CstFloatingLabelInput = () => import('@ui/Input/CstFloatingLabelInput')
const GoogleSignin = () => import('@src/modules/account/components/GoogleSignin.vue')
import { workspaceTypes } from '@modules/setting/store/states/mutation-types'

export default {
  name: 'SignIn',
  components: {
    CstFloatingLabelInput,
    GoogleSignin,
  },
  data() {
    return {
      email: '',
      password: '',
      loginLoader: false,
      remember_me: false,
    }
  },
  computed: {
    ...mapGetters(['getSSOAuthorizationLinks', 'getWorkspaces']),
  },
  created() {
    console.log('METHOD::created::SignIn')
    this.fetchSSOLinks()
  },
  methods: {
    ...mapActions(['fetchDiscover', 'fetchSocialAccounts']),

    async loginAccount() {
      console.debug('Method::loginAccount')

      /**
       * If query params contain Frill.co SSO
       * Authenticate user and redirect to frill redirect URI
       */
      const postData = this.getFrillParams()

      if (!this.email) {
        this.alertMessage('Please enter an email address', 'error')
        return
      }
      if (!this.password) {
        this.alertMessage('Please enter a password', 'error')
        return
      }

      this.loginLoader = true

      try {
        const response = await login({
          username: this.email,
          password: this.password,
          remember_me: this.remember_me,
          ...postData,
        })

        // reset active workspace on login
        this.$store.commit(workspaceTypes.SET_ACTIVE_WORKSPACE, null)

        // check if user has enabled 2FA
        if (response['2fa_enabled']) {
          await this.$router.push({
            name: 'twoFactor',
            params: {
              token: response.user_info,
            },
            query: {
              ...this.$route.query,
            },
          })
          return
        }

        // set the cookies
        this.$store.commit(authenticationTypes.SET_JWT_TOKEN, response.token)
        this.$store.commit(
          authenticationTypes.SET_LOGGED_USER,
          response.logged_user
        )
        this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)

        // custom event for user maven
        this.trackUserMaven('logged_in')

        // If login is request from SSO - Frill.co, redirect user
        if (response.redirect) {
          window.location.href = response.redirect
        }

        if (!this.isUserStateActive(response.user)) {
          return
        }

        // check for redirected url
        if (
          this.$route.query.redirected_url &&
          this.$route.query.redirected_url !== '/logout' &&
          this.$route.query.redirected_url !== '/login'
        ) {
          window.location.href = this.$route.query.redirected_url
          return
        }

        if (response.slug) {
          await this.$router.push({
            name: 'dashboard',
            params: { workspace: response.slug },
          })
          return
        }

        await this.fetchProfile()
        this.isEmailVerified()
      } catch (error) {
        this.alertMessage(error.message || UNKNOWN_ERROR, 'error')
      }

      this.loginLoader = false
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .VueCarousel-pagination {
  justify-content: center;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1490px) {
  .auth-buttons {
    flex-direction: column;

    span {
      width: 100%;
      justify-content: center;
    }
  }
}

.feature-img-container {
  max-width: 66rem !important;
}

.auth-form-container {
  border-radius: 0 !important;
  padding: 4rem 5.4rem !important;

  .auth-form__social-field {
    margin-top: 2.3rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .custom-border {
    border-bottom: 1px solid #cfcece92;
    border-radius: 50%;
  }
}
</style>
